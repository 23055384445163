import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class ListSMSCampaign extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignlist: [],
		}
		//<Route path={`${urlPrefix}/campaign-summary`} ><ListCampaign app={props.app} /></Route>
	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/app/sms/campaigns/' + userId)
			.then(res => {
				this.setState({
					campaignlist: res
				})
			})
			.catch(err => { console.log('Error in fetching Campaigns', err) });

	}
	render() {

		const campaigns = this.state.campaignlist;
		const columns = [
			{
				dataField: 'campaign_id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'campaignName',
				text: 'Campaign Name',
				sort: true,
				searchable: true
			}, {
				dataField: 'campaignStatus',
				text: 'Campaign Status',
				sort: true,
				searchable: true
			}, {
				dataField: 'scheduleTime',
				text: 'Schedule Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'smsSubmitted',
				text: 'SMS Submitted',
				sort: true,
				searchable: false
			}, {
				dataField: 'report',
				text: 'Summary',
				sort: false,
				searchable: false
			}];

		const products = campaigns.map((campaign, index) => {
			let status = '';

			switch (campaign.campaignStatus) {
				case 0:
					status = 'Ready';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				default:
					status = 'Ready';
					break;

			}
			return {
				id: index + 1,
				campaignName: campaign.campaignName,
				campaignStatus: status,
				scheduleTime: campaign.scheduleTime,
				smsSubmitted: campaign.smsSubmitted,
				report: <Link key={index} className="detailed-report-link" to={`${this.props.app.urlPrefix}/sms-campaign-report/${campaign.campaignId}`}><i className="fa fa-eye"></i>View Summary</Link>,
			};
		})
		const { SearchBar, ClearSearchButton } = Search;

		const defaultSorted = [{
			dataField: 'scheduletime',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				<Card.Header>SMS Campaign Summary</Card.Header>
				<Card.Body>
					<div className="campaign-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.campaignlist ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (<ToolkitProvider keyField="id" data={products} columns={columns} search bootstrap4>
									{props => (<div className="">
										Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
										<span className="search-cont"><SearchBar {...props.searchProps} /></span>
										<ClearSearchButton className="btn-primary" {...props.searchProps} />
										<hr />
										<BootstrapTable bordered={true} striped={true} condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="campaignlist-table" {...props.baseProps} {...paginationTableProps} />
										<PaginationListStandalone {...paginationProps} />
									</div>)}
								</ToolkitProvider>)
							}
						</PaginationProvider>}
					</div>
				</Card.Body>
			</Card>
		);
	}
}


export default ListSMSCampaign;