import React from 'react';
import './index.css';

const Footer = ( props ) => {
	return (
	<footer className="app-footer"></footer>
	);
}

export default Footer;
