import React from 'react';
import { Link } from 'react-router-dom'
import './index.css';
const SideNav = (props) => {
	let urlPrefix;
	if (props.app.mode === 'dev') {
		urlPrefix = '/build';
	} else {
		urlPrefix = '';
	}
	return (
		<div className="sidebar">
			<div className="scrollbar-container sidebar-nav">
				{props.app.role === 'ROLE_USER' && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					<li className="nav-title">Custom IVR</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-custom-ivr`}><i className="fa fa-plus-circle"></i> Add Custom IVR</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/ivr-list`}><i className="fa fa-plus-circle"></i> IVR List</Link></li>
					<li className="nav-title">Voice Campaigns</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-campaign`}><i className="fa fa-plus-circle"></i> Compose Voice Campaign</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-campaign-summary`}><i className="fa fa-table"></i> Live Campaign Summary</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/historical-voice-campaign-summary`}><i className="fa fa-table"></i> Historical Campaign Summary</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/schedule-voice-campaign-summary`}><i className="fa fa-table"></i> Schedule Campaign Summary</Link></li>
					<li className="nav-title">Reports</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="fa fa-table"></i> Download Report</Link></li>
					<li className="nav-title">Voice Blacklist</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-blacklist`}><i className="fa fa-plus-circle"></i> Add to Blacklist</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/bulk-voice-blacklist`}><i className="fa fa-plus-circle"></i> Add Bulk Blacklist</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-blacklist`}><i className="fa fa-table"></i> View Blacklist</Link></li>
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-files`}><i className="fa fa-plus-circle"></i> Add Voice File</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> View Voice Files</Link></li>
				</ul>}

				{(props.app.role === 'ROLE_ADMIN') && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					<li className="nav-title">Voice Plans</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="fa fa-plus-circle"></i> Add Voice Plan</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="fa fa-table"></i> View Voice Plans</Link></li>
				</ul>}

				{(props.app.role === 'ROLE_SADMIN') && <ul className="nav">
				<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					<li className="nav-title">CLI Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli`}><i className="fa fa-plus-circle"></i> Upload CLI</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/allocate-cli`}><i className="fa fa-plus-circle"></i> Allocate CLI </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-cli`}><i className="fa fa-table"></i> Archived CLI List </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="fa fa-table"></i> CLI List</Link></li>
					<li className="nav-title">Dialer Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-dialer`}><i className="fa fa-plus-circle"></i> Create Dialer </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/dialer-list`}><i className="fa fa-table"></i> Dialer List</Link></li>
					<li className="nav-title">Trunk Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-trunk`}><i className="fa fa-plus-circle"></i> Create Trunk </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/trunk-list`}><i className="fa fa-table"></i> Trunk List</Link></li>
					<li className="nav-title">Voice Plans</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="fa fa-plus-circle"></i> Add Voice Plan</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="fa fa-table"></i> View Voice Plans</Link></li>
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> Prompts for Approval</Link></li>
				</ul>}

			</div>
		</div>
	);

}

export default SideNav;
